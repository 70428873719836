import { ConfigProvider, theme } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../home";
import { Articles } from "../articles";
import { MainHome } from "../mainHome";
import {Faqs} from "../Faqs";
import {Termscons} from "../Termscons/terms";
import { Contactus } from "../Contactus";
import { Privacypolicies } from "../Privacypolicies";
import { Aboutus } from "../Aboutus";
import { Community } from "../Community/Community";
import { Content } from "../Content/Content";


 
const { defaultAlgorithm } = theme;

export const Main = () => {
  const [config, setConfig] = useState({});

  useEffect(() => {
    setConfig({
      algorithm: defaultAlgorithm,
      token: {
        colorPrimary: "#5E3A9E",
      },
    });
  }, []);
  return (
    <ConfigProvider
    // theme={config}
    >
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/home/:section" element={<Home />}></Route>
        <Route path="/articles/:articleId" element={<Articles />}></Route>
        {/* <Route path="/home" element={<MainHome />}></Route> */}
        <Route path="/faqs" element={<Faqs/>}></Route>
        <Route path="/terms-and-conditions" element={<Termscons/>}></Route>
        <Route path="/contactus" element={<Contactus/>}></Route>
        <Route path="/privacy-policies" element={<Privacypolicies/>}></Route>
        <Route path="/community-guidelines" element={<Community/>}></Route>
        <Route path="/content-policy" element={<Content/>}></Route>
        <Route path="/about-us" element={<Aboutus/>}></Route>
      </Routes>
    </ConfigProvider>
  );
};
