import logo from "../../assets/images/tarana-logo.png";
import Footer from "../Layout/footer";

export const Community = () => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg custom-navbar">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img
                            src={logo}
                            alt="Tarana logo"
                            width="43"
                            height="43"
                            className="d-inline-block align-text-top logoImage me-2"
                        />
                        <span className="navbar-brand-text">tarana</span>
                    </a>
                    <button
                        className="navbar-toggler custom-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        style={{
                            border: "none",
                            backgroundColor: "transparent",
                        }}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div
                        className="collapse navbar-collapse nav-text"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a
                                    className="nav-link active nav-link-home"
                                    aria-current="page"
                                    href="/"
                                >
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-home" href="/home/artists">
                                    For Artists
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-home" href="/home/listeners">
                                    For Listeners
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-home" href="/home/articles">
                                    Articles
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-home" href="#">
                                    About Us
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="container-fluid w-100">
                <h1 className="text-center mb-3 mt-3" style={{ fontFamily: "'Cuprum',sanserif" }}>
                    Community <span style={{ color: "#ff5906" }}>Guidelines</span>
                </h1>
                <div style={{ fontFamily: "'Cuprum',sanserif" }}>
                    <h2>
                        1.      Introduction
                    </h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        Welcome to Tarana (Tarana Media (OPC) Private Limited)! These Community Guidelines are designed to ensure a positive, respectful, and creative environment for all users. By using our platform, you agree to follow these guidelines.
                    </p>
                    <h2>2.      Respect for Others</h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        a) Treat all users with respect, regardless of their background, identity, or beliefs.<br /> b) Do not engage in hate speech, discrimination, or harassment of any kind.<br /> c) Respect the privacy of others. Do not share personal information without consent.
                    </p>
                    <h2>3.      Content Guidelines</h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        a) Only upload content that you have the right to share.<br /> b) Do not post explicit, violent, or offensive content.<br /> c) Avoid spamming or posting excessive promotional material.<br /> d) Label your content appropriately, especially if it contains mature themes.
                    </p>
                    <h2>4.      Intellectual Property</h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        a) Respect copyright laws and other intellectual property rights.<br /> b) Do not upload or share content that infringes on others' copyrights.<br /> c) If you believe your work has been infringed upon, use our reporting system.
                    </p>
                    <h2>5.      Collaboration and Networking</h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        a) Be honest and transparent in your interactions with other users.<br /> b) Honor commitments made for collaborations or freelance work.<br /> c) Communicate clearly and professionally with collaborators and clients.
                    </p>
                    <h2>6.      Freelancing and Payments</h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        a) All financial transactions must be conducted through our platform.<br /> b) Be clear about your rates, terms, and deliverables when offering services.<br /> c) Fulfill your obligations in a timely manner.
                    </p>
                    <h2>7.       Copyright Respect</h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        a) Always credit original artists when sharing or covering their work.<br />
                        b) Obtain necessary licenses before uploading covers, remixes, or samples of others' work.<br />

                        c) Report suspected copyright infringements through our designated channels.
                    </p>
                    <h2>8.      Account Security</h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        a) Do not share your account credentials with others.<br /> b) Do not attempt to access other users' accounts.<br /> c) Report any suspicious activity immediately.
                    </p>
                    <h2>9.      Reporting Violations</h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        a) If you encounter content or behavior that violates these guidelines, report it.<br /> b) Do not engage with or retaliate against users you believe are violating the guidelines.
                    </p>
                    <h2>10.  Consequences of Violations</h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        a) Violations may result in content removal, account suspension, or termination.<br /> b) Serious or repeated violations may lead to permanent bans from the platform.
                    </p>
                    <h2>11.  Changes to Guidelines</h2>
                    <p style={{
                        fontFamily: "'Cuprum',sanserif",
                        lineHeight: "26.57px",
                        fontSize: "19px",
                    }}>
                        We may update these guidelines from time to time. Continued use of the platform after changes constitutes acceptance of the new guidelines.

                    </p>
                </div>
            </div>
            <Footer />
        </div >
    )
}