
import React from "react";
import "./termscondmodules.css";
import Footer from "../Layout/footer";
import logo from "../../assets/images/tarana-logo.png";

export const Termscons = () => {
  return (
    <div className=" container-fluid faqsmodule">
      <nav className="navbar navbar-expand-lg custom-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Tarana logo"
              width="30"
              height="30"
              className="d-inline-block align-text-top logoImage me-2"
            />
            <span className="navbar-brand-text">tarana</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse nav-text"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active nav-link-home"
                  aria-current="page"
                  href="#"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  For Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  For Listeners
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  FAQs
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="terms text-center mb-3 mt-3" style={{ fontFamily: "'Cuprum', sanserif" }}>
        <h1>Terms and <span className="custom-cond"> Conditions</span></h1>
      </div>

      <div>
        <div className="container">
          <h2>1.      Acceptance of Terms</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            By accessing or using Tarana (Tarana Media (OPC) Private Limited), you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our service.<br />
          </p>
          <h2>2.      Description of Service</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            Tarana (Tarana Media (OPC) Private Limited) is a music industry platform that provides:<br /> a) Music streaming<br /> b) Networking and collaboration tools for creators<br /> c) Freelancing opportunities for creators<br /> d) Direct upload options for creator to their share content (singles, EPs, albums, playlists)<br /> e) Social features allowing artists to post updates (music, images, videos)<br /> f) Band formation feature for group account.
          </p>
          <h2>3.      User Accounts</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a)      You must create an account to access certain features of Tarana (Tarana Media (OPC) Private Limited).<br />
            b)       You are responsible for maintaining the confidentiality of your account information.<br />
            c)      You agree to provide accurate and current information during the registration process.<br />
            d)      You may not use another user's account without permission.<br />
            e)      Age Restrictions:<br />
            ·         Users must be at least 13 years old to create an account on Tarana (Tarana Media (OPC) Private Limited).<br />
            ·         If you are under 13 years old, you may only use Tarana (Tarana Media (OPC) Private Limited) with verifiable parental consent.<br />
            ·         Parents or legal guardians of users under 13 must contact Tarana (Tarana Media (OPC) Private Limited) at help@taranamusic.in to provide consent and manage the child's account.<br /> f) Tarana (Tarana Media (OPC) Private Limited) reserves the right to terminate or suspend accounts that violate these age restrictions or any other terms of service.
          </p>
          <h2>4.      Artist Accounts and Verification</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a)      Users registering as artists are required to provide additional personal and professional information for account verification and royalty distribution purposes.<br />
            b)       Personal Information: Artists must provide accurate information including:<br />
            ·         Full legal name<br />
            ·         Father's/Mothers name<br />
            ·         Permanent address<br />
            ·         Digital signature<br />
            ·         A valid government-issued ID<br />
            c)      Professional Information: Artists are required to provide:<br />
            ·         User role (e.g., vocalist, instrumentalist, producer)<br />
            ·         Skills<br />
            ·         Genre specializations<br />
            ·         Languages of performance<br />
            d)      Verification Process:<br />
            ·         Tarana (Tarana Media (OPC) Private Limited) reserves the right to verify the information provided by artists.<br />
            ·         Artists may be required to undergo an additional verification process to confirm their identity and professional status.<br />
            ·         Failure to provide accurate information or to complete the verification process may result in account suspension or termination.<br />
            e)      Information Updates:<br />
            ·         Artists are responsible for keeping their personal and professional information up to date.<br />
            ·         Any changes to this information must be promptly communicated to Tarana (Tarana Media (OPC) Private Limited) through the designated channels.<br />
            f)        Use of Information:<br />
            ·         The information provided will be used for account management, royalty distribution, and as required by applicable laws and regulations.<br />
            ·         Tarana (Tarana Media (OPC) Private Limited) may use professional information to match artists with collaboration or freelancing opportunities within the platform.<br />
            g)       Confidentiality:<br />
            ·         Tarana (Tarana Media (OPC) Private Limited) will treat personal information with the utmost confidentiality and in accordance with our Privacy Policy.<br />
            ·         Government ID information will be stored securely and accessed only for verification purposes.<br />
            h)      Consent:<br />
            ·         By providing this information, artists consent to its collection, storage, and use as described in these Terms and our Privacy Policy.<br />
            i)        Removal of Information:<br />
            ·         Upon account closure, artists may request the removal of their personal information, subject to any legal retention requirements.<br />
          </p>
          <h2>5.      Content Ownership and Licensing</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a) Tarana (Tarana Media (OPC) Private Limited) does not claim ownership of the content uploaded by users.<br /> b) By uploading content, you grant Tarana (Tarana Media (OPC) Private Limited) a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, and display the content within the app and for promotional purposes.<br /> c) You represent and warrant that you have all necessary rights to grant this license.
          </p>
          <h2>6.      Copyright and Intellectual Property</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a)      Tarana (Tarana Media (OPC) Private Limited) respects intellectual property rights and expects users to do the same.<br /> b) We will respond to notices of alleged copyright infringement as per applicable laws.<br /> c) Users are solely responsible for the content they upload and must ensure it does not infringe on any third-party rights.
          </p>
          <h2>7)      Remixes and Covers</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a)      Remixable Content:<br />
            ·         Some content on Tarana (Tarana Media (OPC) Private Limited) may be labeled as "remixable" by the original content owner.<br />
            ·         Users may create remixes or covers of content labeled as "remixable" without seeking additional permission from the original owner.<br />
            b)      Revenue Sharing for Remixes:<br />
            ·         Revenue generated from remixed or covered songs will be split between the remixer and the original content owner.<br />
            ·         The revenue split will be based on the percentage specified by the original content owner or, in absence of such specification, as determined by Tarana (Tarana Media (OPC) Private Limited).<br />
            c)      Non-Remixable Content:<br />
            ·         Content not labeled as "remixable" cannot be remixed or covered without explicit permission from the original owner.<br />
            ·         Users can request permission to remix non-remixable content through Tarana (Tarana Media (OPC) Private Limited)'s in-app communication system.<br />
            d)       Labeling Responsibility:<br />
            ·         Content owners are responsible for accurately labeling their content as "remixable" or "non-remixable".<br />
            ·         Tarana (Tarana Media (OPC) Private Limited) is not responsible for any miscategorization by content owners.<br />
            e)      Rights and Ownership:<br />
            ·         Original content owners retain their copyright and ownership rights even when their content is labeled as "remixable".<br />
            ·         Remixers gain rights only to their specific remix version, not to the original content.<br />
            f)        Removal of Remixable Status:<br />
            ·         Content owners may revoke the "remixable" status of their content at any time.<br />
            ·         Existing remixes created before the status change will remain on the platform unless otherwise specified.<br />
            g)       Quality Control:<br />
            ·         Tarana (Tarana Media (OPC) Private Limited) reserves the right to remove any remix or cover that violates our content guidelines or is of substandard quality.<br />
            h)      Disputes:<br />
            ·         Any disputes regarding remixes or covers will be handled through our standard dispute resolution process.<br />
          </p>
          <h2>7.      Freelancing and Payments</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a) All payments for freelancing services must be processed through Tarana (Tarana Media (OPC) Private Limited).<br /> b) Tarana (Tarana Media (OPC) Private Limited) will deduct a commission from earnings as a service fee.<br /> c) Users agree to comply with all applicable tax laws and regulations.
          </p>
          <h2>8.      User Conduct</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            Users agree not to:<br /> a) Violate any laws or regulations<br /> b) Infringe on the rights of others<br /> c) Upload or transmit any harmful or malicious content<br /> d) Impersonate any person or entity<br /> e) Interfere with the proper functioning of the app
          </p>
          <h2>9.      Termination</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            Tarana (Tarana Media (OPC) Private Limited) reserves the right to terminate or suspend accounts that violate these terms or for any other reason at our discretion.
          </p>
          <h2>10.  Disclaimer of Warranties</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            Tarana (Tarana Media (OPC) Private Limited) is provided "as is" without any warranties, express or implied.
          </p>
          <h2>11.  Limitation of Liability</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            Tarana (Tarana Media (OPC) Private Limited) shall not be liable for any indirect, incidental, special, consequential, or punitive damages.
          </p>
          <h2>12.  Copyright and Music Licensing</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a)      Users are solely responsible for ensuring they have all necessary rights, licenses, and permissions for any music they upload, stream, or otherwise use on Tarana (Tarana Media (OPC) Private Limited).<br />
            b)      This includes, but is not limited to, composition copyrights, sound recording copyrights, mechanical licenses, synchronization licenses, and performance rights.<br />
            c)      Tarana (Tarana Media (OPC) Private Limited) reserves the right to remove any content suspected of copyright infringement without prior notice.<br />
            d)      Users agree to indemnify and hold Tarana (Tarana Media (OPC) Private Limited) harmless from any claims resulting from their use of copyrighted material without proper authorization.<br />
            e)      Tarana (Tarana Media (OPC) Private Limited) implements content identification technology to help detect potentially infringing material. However, this system is not foolproof, and users remain responsible for their uploads.<br />
            f)        Repeated copyright violations will result in account termination.<br />
          </p>
          <h2>13.  DMCA Compliance</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            ·         Tarana (Tarana Media (OPC) Private Limited) complies with the Digital Millennium Copyright Act (DMCA) and similar copyright laws worldwide.<br />
            ·         We provide a clear mechanism for copyright owners to submit takedown notices.<br />
            ·         Users who believe their content was removed in error may submit a counter-notice.<br />
            ·         Tarana (Tarana Media (OPC) Private Limited) will terminate the accounts of repeat infringers.<br />
          </p>
          <h2>14.  Rights Holder Inclusion and Payment</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a)      Users uploading content are responsible for accurately listing all rights holders associated with the uploaded music.<br />
            b)      This includes, but is not limited to, all artists, songwriters, composers, producers, and any other individuals or entities with a stake in the royalties or rights of the music.<br />
            c)      Users must provide complete and accurate information about all rights holders in the designated fields during the upload process.<br />
            d)      Tarana (Tarana Media (OPC) Private Limited) will distribute payments based on the rights holder information provided by the uploader. The uploader is solely responsible for the accuracy of this information.<br />
            e)      Failure to include all rights holders may result in delayed or misdirected payments, and may be grounds for content removal or account suspension.<br />
            f)        Tarana (Tarana Media (OPC) Private Limited) reserves the right to withhold payments if there is a dispute or uncertainty regarding the rights holders of a particular piece of content.<br />
          </p>
          <h2>15.  Changes to Terms</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            We reserve the right to modify these terms at any time. Continued use of the app after changes constitutes acceptance of the new terms.
          </p>
          <h2>16.  Governing Law</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            These terms shall be governed by and construed in accordance with the laws of India.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};