import React from "react";
import logo from "../../assets/images/tarana-logo.png"; // Ensure the path is correct
import Footer from "../Layout/footer";

export const Privacypolicies = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg custom-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Tarana logo"
              width="43"
              height="43"
              className="d-inline-block align-text-top logoImage me-2"
            />
            <span className="navbar-brand-text">tarana</span>
          </a>
          <button
            className="navbar-toggler custom-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse nav-text"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active nav-link-home"
                  aria-current="page"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="/home/artists">
                  For Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="/home/listeners">
                  For Listeners
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="/home/articles">
                  Articles
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  About Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid w-100">
        <h1 className="text-center mb-3 mt-3" style={{ fontFamily: "'Cuprum',sanserif" }}>
          Privacy <span style={{ color: "#ff5906" }}>Policies</span>
        </h1>
        <div style={{ fontFamily: "'Cuprum',sanserif" }}>
          <h2>1.      Introduction</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            This Privacy Policy explains how Tarana (Tarana Media (OPC) Private Limited) collects, uses, and protects your personal information. By using our app, you consent to the data practices described in this policy.<br />
          </p>
          <h2>2.      Information We Collect</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a)      Account Information: When you create an account, we collect your name, email address, phone number gender and date of birth.<br />
            b)      Profile Information: Any additional information you choose to add to your profile, such as profile picture, bio, or location.<br />
            c)      Artist-Specific Information: For users registering as artists, we collect additional information including your Full legal name, Father/Mother’s name, Permanent address, Digital signature, Government-issued ID (Image, type and number)<br />
            d)      Professional Information: For artists, we also collect User role (e.g., vocalist, instrumentalist, producer), Skills, Genre specializations, Languages of performance<br />
            e)      Content: Music, images, videos, and text that you upload or post on the platform.<br />
            f)        Usage Data: Information about how you use the app, including listening history, search queries, and interactions with other users.<br />
            g)       Payment Information: If you use our freelancing features, we collect payment details necessary to process transactions.<br />
            h)      Device Information: We collect information about the devices you use to access our service, including device type, operating system, and browser type.<br />
          </p>
          <h2>3.      How We Use Your Information</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            We use your information to:<br /> a) Provide, maintain, and improve our services<br /> b) Process payments and prevent fraud<br /> c) Personalize your experience and provide recommendations<br /> d) Communicate with you about our services, updates, and promotional offers<br /> e) Analyze usage patterns to enhance our app's functionality<br /> f) Formulate and execute legally binding collaboration agreements between artists to protect content ownership and rights<br /> g) Create and manage freelancing agreements using verified artist information for secure transactions and clear accountability<br /> h) Comply with legal obligations
            Important Note Regarding Legal Documentation: Artist-specific legal information (including Full legal name, Father/Mother's name, Permanent address, Digital signature, and Government-issued ID) is exclusively used for:<br />
            Drafting and executing collaboration agreements between artists<br />
            Creating legally binding freelancing contracts<br />
            Verifying artist identity for payment processing<br />
            Meeting regulatory compliance requirements<br />
            We maintain strict controls over this sensitive information and do not use it for any other purposes, including but not limited to marketing, analytics, or third-party sharing. This information is stored with enhanced security measures and accessed only when necessary for legal agreement formation.<br />
          </p>
          <h2>4.      How We Use Your Information</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            We use your information to:<br />
            a) Provide, maintain, and improve our services<br /> b) Process payments and prevent fraud<br /> c) Personalize your experience and provide recommendations<br /> d) Communicate with you about our services, updates, and promotional offers<br /> e) Analyze usage patterns to enhance our app's functionality<br /> f) Comply with legal obligations<br />
          </p>
          <h2>5.      Information Sharing and Disclosure</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            We may share your information:<br />
            a) With other users as part of the app's social and collaboration features<br /> b) With service providers who perform services on our behalf<br /> c) In response to legal requests or to protect our rights<br /> d) In connection with a merger, sale, or acquisition of all or a portion of our company
          </p>
          <h2>6.      Data Retention</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            We retain your information for as long as your account is active or as needed to provide you services. We will delete your information upon request unless we are legally required to retain it.
          </p>
          <h2>7.      Your Rights and Choices</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            You have the right to:<br />
            a)      Access, correct, or delete your personal information<br /> b) Object to or restrict certain processing of your data<br /> c) Data portability (receiving a copy of your data)<br /> d) Withdraw consent at any time for data processing based on consent
          </p>
          <h2>8.      Copyright Protection Measures</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a.      We may collect and store data related to the music files uploaded to our platform, including but not limited to audio fingerprints, metadata, and usage patterns.<br />
            b.      This information may be used to identify potential copyright infringements and to respond to legal requests from copyright owners<br />
          </p>
          <h2>9.      Data Security</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.
          </p>
          <h2>10.      International Data Transfers</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            Your information may be transferred to and processed in countries other than your own. We ensure appropriate safeguards are in place to protect your information in these cases.
          </p>
          <h2>11.  Children's Privacy</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            a)      Our service is primarily directed to users who are at least 13 years old.<br />
            b)      We do not knowingly collect personal information from children under 13 without parental consent.<br />
            c)      If you are under 13 years old:<br />
            ·         You may only use Tarana (Tarana Media (OPC) Private Limited) with verifiable parental consent.<br />
            ·         Your parent or legal guardian must contact us at help@taranamusic.in to provide consent and manage your account.<br /> d) If we learn that we have collected personal information from a child under 13 without parental consent, we will take steps to delete that information and terminate the account.<br /> e) Parents can review, edit, request deletion, or prohibit further collection of their child's personal information by contacting us at help@taranamusic.in.<br /> f) We comply with the Children's Online Privacy Protection Act (COPPA) and other applicable laws concerning children's privacy.
          </p>
          <h2>12.  Changes to This Policy</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
          </p>
          <h2>13.  Contact Us</h2>
          <p style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}>
            If you have any questions about this Privacy Policy, please contact us at help@taranamusic.in
            Last Updated: 2 November 2024
          </p>
        </div>
      </div>

      <Footer />
    </div>

  );
};