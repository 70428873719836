import logo from "../../assets/images/tarana-logo.png";
import Footer from "../Layout/footer";

export const Content = () => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg custom-navbar">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img
                            src={logo}
                            alt="Tarana logo"
                            width="43"
                            height="43"
                            className="d-inline-block align-text-top logoImage me-2"
                        />
                        <span className="navbar-brand-text">tarana</span>
                    </a>
                    <button
                        className="navbar-toggler custom-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        style={{
                            border: "none",
                            backgroundColor: "transparent",
                        }}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div
                        className="collapse navbar-collapse nav-text"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a
                                    className="nav-link active nav-link-home"
                                    aria-current="page"
                                    href="/"
                                >
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-home" href="/home/artists">
                                    For Artists
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-home" href="/home/listeners">
                                    For Listeners
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-home" href="/home/articles">
                                    Articles
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-home" href="#">
                                    About Us
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container-fluid w-100">
                <h1 className="text-center mb-3 mt-3" style={{ fontFamily: "'Cuprum',sanserif" }}>
                    Content <span style={{ color: "#ff5906" }}>Policies</span>
                </h1>
                <div style={{ fontFamily: "'Cuprum',sanserif" }}>
                    <h2>1.      Purpose</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        This Content Policy outlines the types of content that are permitted on Tarana (Tarana Media (OPC) Private Limited), as well as our approach to content moderation and management.
                    </p>
                    <h2>2.      Allowed Content</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a) Music: Original songs, remixes (with proper rights), cover versions (with appropriate licenses) <br />
                        b) Images: Artist photos, album artwork, promotional images<br /> c) Videos: Music videos, live performances, behind-the-scenes content<br /> d) Text: Song lyrics, artist bios, album descriptions, updates, and posts
                    </p>
                    <h2>3.      Remixes and Covers</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a)      Remixable Content:<br />
                        ·         Content labeled as "remixable" by the original owner can be remixed or covered by other users.<br />
                        ·         Users must respect the "remixable" or "non-remixable" status of all content.<br />
                        b)      Creating Remixes:<br />
                        ·         When creating a remix or cover, users must clearly credit the original work and artist.<br />
                        ·         Remixes and covers must not be derogatory or significantly alter the fundamental character of the original work without permission.<br />
                        c)      Requesting Permission:<br />
                        ·         For non-remixable content, users must request and receive permission from the original owner before creating a remix or cover.<br />
                        ·         All communication regarding permissions must be conducted through Tarana (Tarana Media (OPC) Private Limited)'s messaging system.<br />
                        d)      Revenue Sharing:<br />
                        ·         Users who create remixes or covers of "remixable" content agree to the revenue sharing terms set by the original content owner or Tarana (Tarana Media (OPC) Private Limited).<br />
                        e)      Original Content:<br />
                        ·         Users who upload original content can choose to label it as "remixable" or "non-remixable".<br />
                        ·         Content owners should carefully consider their choice, as it affects how others can use their work.<br />
                        f)        Monitoring and Enforcement:<br />
                        ·         Tarana (Tarana Media (OPC) Private Limited) will monitor remix and cover activities to ensure compliance with these policies.<br />
                        ·         Violations may result in content removal, account suspension, or termination.<br />
                        g)       Dispute Resolution:<br />
                        ·         In case of disputes over remixes or covers, Tarana (Tarana Media (OPC) Private Limited) will mediate between the parties involved.<br />
                        ·         Tarana (Tarana Media (OPC) Private Limited) reserves the right to make final decisions in any disputes.<br />
                    </p>
                    <h2>4.      Prohibited Content</h2><br />
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        The following types of content are not allowed on Tarana (Tarana Media (OPC) Private Limited):<br />
                        a) Copyrighted material without proper authorization<br /> b) Explicit sexual content or pornography<br /> c) Graphic violence or gore<br /> d) Hate speech or content promoting discrimination<br /> e) Illegal activities or content<br /> f) Spam or deceptive content<br /> g) Personal information of others without consent<br /> h) Malware or phishing content<br /> i) other non-music content<br />
                    </p>
                    <h2>5.      Age Restrictions</h2><br />
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a) Content with mature themes must be appropriately labeled.<br /> b) Users must adhere to age restrictions for certain content as required by local laws.
                    </p>
                    <h2>6.      Content Ownership and Licensing</h2><br />
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a) Users retain ownership of the content they upload.<br /> b) By uploading content, users grant Tarana (Tarana Media (OPC) Private Limited) a license to use, distribute, and display the content within the platform.
                    </p>
                    <h2>7.      Content Moderation</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a) We use a combination of automated systems and human review for content moderation.<br /> b) Users can report content that violates our policies.<br /> c) We reserve the right to remove any content that violates this policy.
                    </p>
                    <h2>8.       Music Copyright Compliance</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a)      Only upload music for which you own all necessary rights or have obtained proper licenses.<br />
                        b)      Remixable Content:<br />
                        ·         You may create remixes or covers of content labeled as "remixable" without seeking additional permission.<br />
                        ·         Revenue from these remixes or covers will be shared as specified by the original content owner or Tarana (Tarana Media (OPC) Private Limited)'s policies.<br />
                        c)       Non-Remixable Content: For content not labeled as "remixable", you must obtain explicit permission from the original owner through Tarana (Tarana Media (OPC) Private Limited)'s in-app communication system before creating a remix or cover<br />
                        d)      Provide accurate metadata for all uploaded music, including composer, performer, and rights holder information.<br />
                        e)      Tarana (Tarana Media (OPC) Private Limited) uses content identification technology and may block uploads that match copyrighted works in our database.<br />
                        f)        We cooperate with music rights organizations and may share usage data to ensure proper royalty distributions.<br />
                    </p>
                    <h2>9.      Licensing for Commercial Use</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a)      Music available for streaming on our platform is for personal, non-commercial use only, unless otherwise specified.<br />
                        b)      Users seeking to use music for commercial purposes (e.g., in videos, advertisements) must obtain separate licensing.<br />
                    </p>
                    <h2>10.  Territory Restrictions</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a)      Due to licensing restrictions, some music may not be available in all geographic regions.<br />
                        b)      Users are responsible for adhering to territory restrictions when uploading and sharing content.<br />
                    </p>
                    <h2>11.  Legal Compliance</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a)      Tarana (Tarana Media (OPC) Private Limited) will comply with valid legal requests related to copyright matters, which may include sharing user information with copyright owners or law enforcement.<br />
                        b)      We reserve the right to terminate services to any user found to be repeatedly infringing copyrights or violating intellectual property laws.<br />
                    </p>
                    <h2>12.  Rights Holder Declaration</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a)      When uploading content, users must declare all rights holders associated with the music.<br />
                        b)      This declaration must include:<br />
                        ·         All featured and non-featured artists<br />
                        ·         Songwriters and composers<br />
                        ·         Producers<br />
                        ·         Publishers<br />
                        ·         Any other individuals or entities entitled to royalties or rights from the use of the music<br />
                        c)      Users are required to provide accurate splits or percentages for royalty distribution among the declared rights holders.<br />
                        d)      The uploading user bears full responsibility for the accuracy and completeness of the rights holder information.<br />
                        e)      Tarana (Tarana Media (OPC) Private Limited) may request additional documentation to verify rights holder information at any time.<br />
                        f)        Intentional misrepresentation of rights holder information is a serious violation of our policies and may result in immediate account termination and legal action.<br />
                    </p>
                    <h2>13.  Liability for Rights Holder Information</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        Users agree to indemnify and hold Tarana (Tarana Media (OPC) Private Limited) harmless from any claims, damages, or legal actions arising from inaccurate or incomplete rights holder information they provide.<br />
                        In the event of a dispute regarding rights holder information, Tarana (Tarana Media (OPC) Private Limited) reserves the right to suspend content and withhold payments until the dispute is resolved.<br />
                        Users are responsible for updating rights holder information if there are any changes in ownership or royalty splits.<br />
                    </p>
                    <h2>14.  Copyright Infringement</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a) We comply with copyright laws and will respond to notices of alleged copyright infringement.<br /> b) Users can submit copyright infringement claims through our designated process.
                    </p>
                    <h2>15.  Content Removal and Appeals</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a) Content that violates our policies will be removed.<br /> b) Users will be notified if their content is removed for policy violations.<br /> c) Users can appeal content removal decisions through our appeal process.
                    </p>
                    <h2>16.  Consequences of Violations</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        a) Violations may result in content removal, account suspension, or termination.<br /> b) Repeated or severe violations may result in permanent account bans.
                    </p>
                    <h2>17.  Changes to Content Policy</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        We may update this Content Policy from time to time. Continued use of the platform after changes constitutes acceptance of the new policy.
                    </p>
                    <h2>18.  Disclaimer</h2>
                    <p className="w-100 p-3" style={{ fontSize: "23px", fontWeight: "400" }}>
                        While we strive to enforce this policy consistently, we cannot monitor all content on the platform. Users are responsible for the content they post and should report any violations they encounter.

                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}